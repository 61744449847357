import Service from '../Service'

export default class extends Service {
  constructor (cid, id) {
    const url = ['relationship', 'companies', cid, 'addresses']
    if (id) {
      url.push(id)
    }
    super(url)
  }
}
