<template>
  <gf-base>
    <div class="details">
      <el-dialog title="Delete Customer Group" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                  <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                  <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to delete this company. This action cannot be undone. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
        </template>
      </el-dialog>
      <gf-card :title="company.name" subtitle="Company Information" v-loading="loading">
        <template #toolbar>
          <gf-button class="mr-2" type="primary" @click="edit">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            Edit
          </gf-button>
          <gf-button class="mr-2" type="danger" @click="deleteModalVisible = true">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
          <gf-button type="secondary" @click="back">
            <template slot="icon">
              <span class="svg-icon svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                    <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                  </g>
                </svg>
              </span>
            </template>
            Back
          </gf-button>
        </template>
        <div class="header-details">
          <div class="image-container"><i class="el-icon-suitcase"></i></div>
          <div class="headers-extra-details">
            <div class="header-column-1">
              <div class="row">
                <div class="left">Type</div>
                <div class="right">
                  <span v-if="company.type === 1">Business Customer</span>
                  <span v-else-if="company.type === 2">Consumer</span>
                  <span v-else-if="company.type === 3">Supplier</span>
                  <span v-else>Unknown</span>
                </div>
              </div>
              <div class="row">
                <div class="left">Code</div>
                <div class="right">{{ company.code }}</div>
              </div>
              <div class="row">
                <div class="left">Tax Number</div>
                <div class="right">{{ company.tax_number }}</div>
              </div>
            </div>
            <div class="header-column-2">
              <div class="row">
                <div class="left">Phone</div>
                <div class="right">{{ company.phone_number }}</div>
              </div>
              <div class="row">
                <div class="left">Email</div>
                <div class="right">{{ company.email }}</div>
              </div>
              <div class="row">
                <div class="left">Website</div>
                <div class="right">{{ company.website }}</div>
              </div>
              <div class="row">
                <div class="left">Description</div>
                <div class="right">{{ company.description }}</div>
              </div>
            </div>
            <div class="header-column-3">
              <div class="row" v-if="company.type !== 3">
                <div class="left">Customer Group</div>
                <div class="right">
                  <span v-if="company.setting.customer_group.name">{{ company.setting.customer_group.name }}</span>
                  <span v-else>None Set</span>
                </div>
              </div>
              <div class="row">
                <div class="left">Price List</div>
                <div class="right">
                  <span v-if="company.setting.customer_group.name">
                    <span v-if="company.setting.customer_group.price_list.name">{{ company.setting.customer_group.price_list.name }}</span>
                    <span v-else>None Set</span>
                  </span>
                  <span v-else-if="company.setting.price_list.name">{{ company.setting.price_list.name }}</span>
                  <span v-else>None Set</span>
                </div>
              </div>
              <div class="row" v-if="company.type !== 3">
                <div class="left">Discount Rate</div>
                <div class="right">
                  <span v-if="company.setting.customer_group.name">
                    <span v-if="company.setting.customer_group.discount_rate">{{ company.setting.customer_group.discount_rate }} %</span>
                    <span v-else>None Set</span>
                  </span>
                  <span v-else-if="company.setting.discount">{{ company.setting.discount }} %</span>
                  <span v-else>None Set</span>
                </div>
              </div>
              <div class="row" v-if="company.type !== 3">
                <div class="left">Payment Term</div>
                <div class="right">
                  <span v-if="company.setting.customer_group.name">
                    <span v-if="company.setting.customer_group.payment_term.name">{{ company.setting.customer_group.payment_term.name }}</span>
                    <span v-else>None Set</span>
                  </span>
                  <span v-else-if="company.setting.payment_term.name">{{ company.setting.payment_term.name}}</span>
                  <span v-else>None Set</span>
                </div>
              </div>
              <div class="row" v-if="company.type !== 3">
                <div class="left">Payment Method</div>
                <div class="right">{{ company.setting.payment_method.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </gf-card>
    </div>
    <div class="list">
      <gf-card :tab="tabs" :active-tab="activeTab" @tab-click="tabClick" v-loading="loadingList">
        <template #toolbar>
          <el-dialog title="Add New Contact" :visible.sync="addContactModalVisible" append-to-body v-loading="addContactModalLoading" :close-on-click-modal="false">
            <gf-form>
              <template #alert>
                <gf-alert>
                  <template #icon>
                    <span class="svg-icon svg-icon-primary svg-icon-md">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M11.613922,13.2130341 C11.1688026,13.6581534 10.4887934,13.7685037 9.92575695,13.4869855 C9.36272054,13.2054673 8.68271128,13.3158176 8.23759191,13.760937 L6.72658218,15.2719467 C6.67169475,15.3268342 6.63034033,15.393747 6.60579393,15.4673862 C6.51847004,15.7293579 6.66005003,16.0125179 6.92202169,16.0998418 L8.27584113,16.5511149 C9.57592638,16.9844767 11.009274,16.6461092 11.9783003,15.6770829 L15.9775173,11.6778659 C16.867756,10.7876271 17.0884566,9.42760861 16.5254202,8.3015358 L15.8928491,7.03639343 C15.8688153,6.98832598 15.8371895,6.9444475 15.7991889,6.90644684 C15.6039267,6.71118469 15.2873442,6.71118469 15.0920821,6.90644684 L13.4995401,8.49898884 C13.0544207,8.94410821 12.9440704,9.62411747 13.2255886,10.1871539 C13.5071068,10.7501903 13.3967565,11.4301996 12.9516371,11.8753189 L11.613922,13.2130341 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Input the details of the new contact below
                </gf-alert>
              </template>
              <el-form size="small" label-width="120px" :model="contact" ref="contact" :rules="contactRules">
                <el-form-item label="First Name" prop="fname">
                  <el-input v-model="contact.fname"></el-input>
                </el-form-item>
                <el-form-item label="Last Name" prop="lname">
                  <el-input v-model="contact.lname"></el-input>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                  <el-input v-model="contact.email"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number" prop="phone_number">
                  <el-input v-model="contact.phone_number"></el-input>
                </el-form-item>
                <el-form-item label="Fax" prop="fax_number">
                  <el-input v-model="contact.fax_number"></el-input>
                </el-form-item>
                <el-form-item label="Mobile" prop="mobile_number">
                  <el-input v-model="contact.mobile_number"></el-input>
                </el-form-item>
                <el-form-item label="Title" prop="title">
                  <el-input v-model="contact.title"></el-input>
                </el-form-item>
                <el-form-item label="Department" prop="department">
                  <el-input v-model="contact.department"></el-input>
                </el-form-item>
                <el-form-item label="Notes" prop="notes">
                  <el-input type="textarea" v-model="contact.notes"></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <gf-button type="secondary" @click="addContactModalVisible = false">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                            <rect x="0" y="7" width="16" height="2" rx="1"/>
                            <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Close
                </gf-button>
                <gf-button type="primary" @click="newContact">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-white svg-icon-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Add
                </gf-button>
              </template>
            </gf-form>
          </el-dialog>
          <el-dialog title="Add New Address" :visible.sync="addAddressModalVisible" append-to-body v-loading="addAddressModalLoading" :close-on-click-modal="false">
            <gf-form>
              <template #alert>
                <gf-alert>
                  <template #icon>
                    <span class="svg-icon svg-icon-primary svg-icon-md">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M18,2 L20,2 C21.6568542,2 23,3.34314575 23,5 L23,19 C23,20.6568542 21.6568542,22 20,22 L18,22 L18,2 Z" fill="#000000" opacity="0.3"/>
                          <path d="M5,2 L17,2 C18.6568542,2 20,3.34314575 20,5 L20,19 C20,20.6568542 18.6568542,22 17,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,3 C4,2.44771525 4.44771525,2 5,2 Z M12,11 C13.1045695,11 14,10.1045695 14,9 C14,7.8954305 13.1045695,7 12,7 C10.8954305,7 10,7.8954305 10,9 C10,10.1045695 10.8954305,11 12,11 Z M7.00036205,16.4995035 C6.98863236,16.6619875 7.26484009,17 7.4041679,17 C11.463736,17 14.5228466,17 16.5815,17 C16.9988413,17 17.0053266,16.6221713 16.9988413,16.5 C16.8360465,13.4332455 14.6506758,12 11.9907452,12 C9.36772908,12 7.21569918,13.5165724 7.00036205,16.4995035 Z" fill="#000000"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Input the details of the new address below
                </gf-alert>
              </template>
              <el-form size="small" label-width="120px" :model="address" ref="address" :rules="addressRules">
                <el-form-item label="Label" prop="label">
                  <el-input v-model="address.label"></el-input>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                  <el-input v-model="address.email"></el-input>
                </el-form-item>
                <el-form-item label="Phone Number" prop="phone_number">
                  <el-input v-model="address.phone_number"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 1" prop="address_line_1">
                  <el-input v-model="address.address_line_1"></el-input>
                </el-form-item>
                <el-form-item label="Address Line 2" prop="address_line_2">
                  <el-input v-model="address.address_line_2"></el-input>
                </el-form-item>
                <el-form-item label="City" prop="city">
                  <el-input v-model="address.city"></el-input>
                </el-form-item>
                <el-form-item label="Zip Code" prop="zip_code">
                  <el-input v-model="address.zip_code"></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <gf-button type="secondary" @click="addAddressModalVisible = false">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                            <rect x="0" y="7" width="16" height="2" rx="1"/>
                            <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Close
                </gf-button>
                <gf-button type="primary" @click="newAddress">
                  <template slot="icon">
                    <span class="svg-icon svg-icon-white svg-icon-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                          <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                        </g>
                      </svg>
                    </span>
                  </template>
                  Add
                </gf-button>
              </template>
            </gf-form>
          </el-dialog>
          <gf-button type="primary" v-if="activeTab === 1" @click="addContactModalVisible = true">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            New Contact
          </gf-button>
          <gf-button type="primary" v-else-if="activeTab === 2" @click="addAddressModalVisible = true">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                    <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            New Address
          </gf-button>
          <gf-button type="success" v-else-if="activeTab === 3" @click="handlePrint">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M16,17 L16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 L8,17 L5,17 C3.8954305,17 3,16.1045695 3,15 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,15 C21,16.1045695 20.1045695,17 19,17 L16,17 Z M17.5,11 C18.3284271,11 19,10.3284271 19,9.5 C19,8.67157288 18.3284271,8 17.5,8 C16.6715729,8 16,8.67157288 16,9.5 C16,10.3284271 16.6715729,11 17.5,11 Z M10,14 L10,20 L14,20 L14,14 L10,14 Z" fill="#000000"/>
                    <rect fill="#000000" opacity="0.3" x="8" y="2" width="8" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            Print
          </gf-button>
        </template>
        <router-view :active.sync="activeTab" :reload.sync="reload" :pagination="pagination"></router-view>
        <template #footer v-if="activeTab !== 3">
          <gf-pagination :pagination="pagination" @change="changePage"></gf-pagination>
        </template>
      </gf-card>
    </div>
  </gf-base>
</template>

<style lang="scss" scoped>
.details {
  margin-bottom: 25px;
}

.list {
  flex-grow: 1;
}

.header-details {
  display: flex;
  background: #fff;

  .image-container {
    display: flex;
    font-size: 110px;
    flex-basis: 170px;
    justify-content: center;
    align-items: center;
  }

  .headers-extra-details {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;

    .header-column-1 {
      flex-grow: 1;
    }

    .header-column-2 {
      flex-grow: 1;
    }

    .header-column-3 {
      flex-grow: 1;
    }

    .row {
      display: flex;
      line-height: 17.5px;

      .left {
        width: 50%;
        text-align: right;
        border-right: 1px solid #dadada;
        margin-right: 10px;
        padding: 5px 10px 5px 0;
        font-weight: 500;
      }

      .right {
        width: 50%;
        padding: 5px 10px 5px 0;
        text-align: left;
      }
    }
  }
}
</style>

<script>
import CompanyService from '@/services/v1/Company'
import CompanyContactService from '@/services/v1/CompanyContact'
import CompanyAddressService from '@/services/v1/CompanyAddress'

export default {
  data () {
    return {
      companyId: this.$route.params.id,

      // main
      company: {
        name: null,
        type: null,
        code: null,
        tax_number: null,
        phone_number: null,
        fax_number: null,
        website: null,
        email: null,
        description: null,
        customer_group: {
          name: null
        },
        setting: {
          discount: null,
          minimum_order_value: null,
          customer_group: {
            name: null,
            discount_rate: null,
            price_list: {
              name: null
            },
            payment_term: {
              name: null
            }
          },
          price_list: {
            name: null
          },
          payment_term: {
            name: null
          },
          payment_method: {
            name: null
          }
        }
      },
      contact: {
        id: null,
        fname: null,
        lname: null,
        email: null,
        phone_number: null,
        fax_number: null,
        mobile_number: null,
        title: null,
        department: null,
        notes: null
      },
      address: {
        label: null,
        email: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        zip_code: null
      },

      // helper
      activeTab: 1,
      tabs: [
        { id: 1, label: 'Contacts', routeName: 'company-contacts' },
        { id: 2, label: 'Addresses', routeName: 'company-addresses' },
        { id: 3, label: 'Bill of Materials', routeName: 'company-materials' }
      ],
      reload: false,
      loading: false,
      loadingList: false,

      // modal
      addContactModalVisible: false,
      addContactModalLoading: false,
      addAddressModalVisible: false,
      addAddressModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      // rules
      contactRules: {
        fname: [
          { required: 'true', message: 'First Name can\'t be blank', trigger: 'blur' }
        ]
      },
      addressRules: {
        label: [
          { required: true, message: 'Label can\'t be blank', trigger: 'blur' }
        ],
        address_line_1: [
          { required: true, message: 'Address Line 1 can\'t be blank', trigger: 'blur' }
        ]
      },

      // Pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  methods: {
    // navigation
    edit () {
      this.$router.push({ name: 'company-edit', params: { id: this.companyId } })
    },
    back () {
      this.$router.push({ name: 'company-list' })
    },
    handlePrint () {
      this.$router.push({ name: 'company-material-print', params: { id: this.companyId } })
    },

    // main
    async remove () {
      try {
        this.deleteModalLoading = true
        const cmService = new CompanyService(this.companyId)
        await cmService.del()

        this.deleteModalVisible = false
        this.$message.success(`Successfully deleted ${this.company.name}`)
        this.$router.push({ name: 'company-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    async newContact () {
      this.$refs.contact.validate()
        .then(async () => {
          try {
            this.addContactModalLoading = true
            const contact = this.$_.cloneDeep(this.contact)

            this.$Sanitize(contact)

            const ccService = new CompanyContactService(this.companyId)
            await ccService.create(contact)
            this.$message.success('New contact successfully added')

            this.addContactModalVisible = false
            this.reload = true
            this.$ClearField('contact')
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addContactModalLoading = false
          }
        })
        .catch(() => {})
    },
    async newAddress () {
      this.$refs.address.validate()
        .then(async () => {
          try {
            this.addAddressModalLoading = true
            const address = this.$_.cloneDeep(this.address)

            this.$Sanitize(address)

            const caService = new CompanyAddressService(this.companyId)
            await caService.create(address)
            this.$message.success('New address successfully added')

            this.addAddressModalVisible = false
            this.reload = true
            this.$ClearField('address')
          } catch (error) {
            this.$Error(error)
          } finally {
            this.addAddressModalLoading = false
          }
        })
        .catch(() => {})
    },

    tabClick (tab) {
      this.pagination.total = 0
      this.pagination.page.number = 1

      this.$router.push({ name: this.tabs[tab - 1].routeName })
    },
    changePage () {
      this.reload = true
    },

    async getCompany () {
      try {
        this.loading = true
        this.loadingList = true
        const cmService = new CompanyService(this.companyId)
        const result = await cmService.get()

        this.company.name = result.data.name
        this.company.type = result.data.type
        this.company.code = result.data.code
        this.company.tax_number = result.data.tax_number
        this.company.phone_number = result.data.phone_number
        this.company.email = result.data.email
        this.company.website = result.data.website
        this.company.description = result.data.description

        if (result.data.setting.length === 1) {
          this.company.setting.discount = result.data.setting[0].discount

          if (result.data.setting[0].customer_group) {
            this.company.setting.customer_group.name = result.data.setting[0].customer_group.name
            this.company.setting.customer_group.discount_rate = result.data.setting[0].customer_group.discount_rate
            if (result.data.setting[0].customer_group.price_list) {
              this.company.setting.customer_group.price_list.name = result.data.setting[0].customer_group.price_list.name
            }

            if (result.data.setting[0].customer_group.payment_term) {
              this.company.setting.customer_group.payment_term.name = result.data.setting[0].customer_group.payment_term.name
            }
          }

          if (result.data.setting[0].payment_term) {
            this.company.setting.payment_term.name = result.data.setting[0].payment_term.name
          }

          if (result.data.setting[0].price_list) {
            this.company.setting.price_list.name = result.data.setting[0].price_list.name
          }

          if (result.data.setting[0].payment_method) {
            this.company.setting.payment_method.name = result.data.setting[0].payment_method.name
          }
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
        this.loadingList = false
      }
    }
  },
  async mounted () {
    this.loading = true
    this.loadingList = true
    await this.getCompany()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Relationship', route: '' },
      { title: 'Companies' },
      { title: this.company.name }
    ])
  }
}
</script>
